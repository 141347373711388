/* GoFundMePopup.css */
.gfm-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .gfm-popup {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 600px;
    width: 90%;
    position: relative;
    overflow: hidden; /* Prevents content from overflowing the box */
  }
  
  .gfm-closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: black; /* Color for the close button */
  }
  
  .gfm-widget {
    width: 100%;
    height: 500px; /* Set fixed height for the widget */
    background-color: #f4f4f4; /* Add a background color to make it visible */
    border-radius: 8px; /* Optional, rounded corners */
  }
  